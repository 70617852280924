//=================================================
// Logo
//=================================================

/*=============================
// Loading
=============================*/
.loading {
    .logo {
        img {
            width: 8rem;
        }
    }
}

/*=============================
// Header
=============================*/
.site-header,
#menu-modal .modal-header {
    .logo {
        img {
            width: 8.210526rem;
        }
    }
}

/*=============================
// Footer
=============================*/
.site-footer {
    .logo {
        img {
            width: 8.210526rem;
        }
    }
}
