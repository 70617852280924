//=================================================
// Subscribe
//=================================================

.subscribe {
    background-color: $subscribe_background_color;
    padding-top: $subscribe_padding_b;
    padding-bottom: $subscribe_padding_t;
    position: relative;

        h4 {
            font-family: $font_primary;
            font-weight: 400;
        }

    }
    
    .wrapper {
        position: relative;


        .row {
            align-items:center;
            margin-left: auto;
        }

        .first-column {
            h4 {
                // font-family: $font_primary_bold;
                font-weight: 400;
            }
        }

        @media only screen and (max-width: 768px) {
            .second-column {
                display: none;
            }
        }

        .second-column {
            padding-left: $space_xl/4;


                h4 {
                  font-weight: 400;
                text-decoration: underline;  
                text-decoration-color: $subscribe_text_color;
                }
              

            &:hover {
                > a:not(.disabled):not(.active) {
                    color: $subscribe_text_color;
                }
            }
        }
        
        @media only screen and (min-width: 768px) {
            .third-column {
                display: none
            }
        }

        .third-column {
            padding-left: $space_xl/4;
            margin-top: $space_xs/5;
            // align-items:center;
            h4 {
                // display: none;
                font-family: $font_primary_bold;
                font-weight: 400;
            }

            a {
                font-size: xx-large;
                font-family: $font_primary_bold;
                
            }

            &:hover {
                > a:not(.disabled):not(.active) {
                    color: $subscribe_text_color;
                }
            }
        
        }

        h4 {
            font-family: $font_primary;
            font-weight: 400;
        }
    }



