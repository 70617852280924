//=================================================
// Technology
//=================================================


.technology-video {
	
	// /* Centering the container's content vertically 
	// and horizontally */
	text-align: left;
	display: flex;
	  
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index:-1;
		position: relative;
	  }

	  .video-text {
		position: absolute;
		color: white !important;
		z-index: 1 !important;
		bottom: 0;

		.wrapper {

			h1 {
				color: white !important;
				font-family: $font_primary !important;

			}
		}
	  }

	  /* For mobile phones: */
	@media only screen and (max-width: 768px) {
		video {
			min-height: 700px;
		}
	}
	  
}

.technology-top {
	background-color: $body_background_color_secondary;
	padding-top: $space_xl;
	padding-bottom: $space_xl;
	height: 100%;

	.wrapper {
		width: 60%;
	}

	/* For mobile phones: */
	@media only screen and (max-width: 768px) {
		.wrapper {
			width: 100%;
		}
	}

	h2 {
		color: $body_text_tertiary_color !important;
		font-family: $font_primary !important;
	}
	
}


 // Tech details with background blocks
//=================================================
.technology-details {
	padding-top: $space_xl;
	padding-bottom: $space_xl;
	background-color: #F9F2E8;
	background-image:  linear-gradient(#F6ECDC 2px, transparent 2px), linear-gradient(to right, #F6ECDC 2px, #F9F2E8 2px);
	background-size: 60px 60px;
}






 // Alternating, two-column images
//=================================================

// Grid adjustments for half fluid image, half text layouts

// Add class .half-fluid to outer section container
.half-fluid {
	background-color: white;

	p {
		color: black;
	}
	
	// Add .left-half or .right-half class to the image column depending on which side it goes on
	// On mobile, images stack, break out of the container, and go full width
	.left-half,
	.right-half {
		
		
	left: 50%;
	margin-left: -50vw;
	// max-width: 10vw;
	
	position: relative;
	right: 50%;
	width: 100vw;
	padding: 0px;
	}
  
	// Background image
	.image1 {
	  background-image: url("../../img/pages/technology/gridformer.png");
	  background-size: cover;
	  background-position: center;
	  min-height: 400px;
	  height: 100%;
	}

		// Background image
	.image2 {
		background-image: url("../../img/pages/technology/segformer.png");
		background-size: cover;
		background-position: center;
		min-height: 400px;
		height: 100%;
		}
  }
  
  // When compiling with BS Sass files, use: @include media-breakpoint-up(lg)
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
  
	.half-fluid {
	  .left-half {
		padding-left:0 !important;
		position: absolute;
		left: 0;
		right: 50%;
		margin-left: auto;
		width: auto;
	  }
  
	  .right-half {
		padding-right:0 !important;
		position: absolute;
		right: 0;
		left: 50%;
		margin-left: auto;
		width: auto;
	  }
	}
  }


  .technology-middle {
	background-color: $body_background_color_secondary;
	overflow: hidden;

	article {
		background-color: $body_background_color_secondary;
		float: left;
		
		&:nth-child(even) {
		  .image {
			float: left;
		  }
		  .text {
			float: right;
		  }
		}
		h2,h3,h5,p  {
			color: $body_text_secondary_color !important;
			font-family: $font_primary !important;
		}

		.text-wrapper {
			width: 80% ;
			margin: auto;
			padding: 0.5rem;
			
		}

		/* For mobile phones: */
		@media only screen and (max-width: 768px) {
			.text-wrapper {
				width: 100%;
			}
		}
		  
	  }
	  
	  
	  img {
		max-width: 100%;
	  }
	  
	  .box {
		box-sizing: border-box;
		float: left;
		margin: 0;
		padding: 0 0rem;
		width: 100%;
	  }
	  
	  .col-50 {
		width: 50%;
	  }
	  
	  @media (max-width: 979px) {
		.col-50 {
		  width: 100%;
		}

		.re-order {
			display: flex;
			display: -ms-flex;
			flex-direction: column;
		  }
		  .re-order .text {
			order: 2;
		  }
		  .re-order .image {
			order: 1;
		  }
		
	  }

	
}
