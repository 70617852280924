//=================================================
// Modal
//=================================================

.modal {
    background-color: $modal_background_color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    min-width: 320px;

    .modal-dialog {
        .modal-header {
            .close {
                &.btn {
                    padding: 0;
                    margin: 0 0 0 auto;
                    font-size: 1.263rem;
                }
            }
        }
    }
}

// Color
.modal {
    .modal-dialog {
        .modal-content {
            color: $modal_content_color;

            h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cit, code,
            del, dfn, em, ins, q, samp, small, strong, sub, sup, b, i, hr, dl, dt, dd,
            ol, ul, li, fieldset, legend, label, span, caption, figcaption, details, hgroup, summary, mark, audio, video {
                &:not(.btn) {
                    color: inherit;
                }
            }

            .btn-link {
                color: inherit;
            }

            a {
                &:not(.transform-scale-h):not(.btn) {
                    &:hover {
                        color: $body_links_hover_color;
                    }
                }
            }

            .btn-link {
                &:hover {
                    color: $body_links_hover_color;
                }
            }

            .close {
                span {
                    color: $modal_content_color;
                }
            }

            input, select, textarea {
                color: $modal_content_color;
                border-color: rgba($modal_content_color, 0.5);

                &:focus {
                    border-color: $modal_content_color;
                }

                &::placeholder {
                    color: rgba($modal_content_color, 0.5);
                }
            }
        }
    }
}

/*=============================
// Modal size
=============================*/
.modal-full {
    max-width: 100%;
    margin: 0;
}

/*=============================
// Modal header
=============================*/
.modal-header-top {
    position: absolute;
    z-index: 99;
    width: 100%;
}

/*=============================
// Modal body
=============================*/
.modal-body-centered {
    display: flex;
    align-items: center;
    min-height: 100%;
    padding: 0;

    &::before {
        content: "";
        display: block;
        height: 100vh;
    }
}

/*=============================
// Modal title
=============================*/
.modal-title {
    margin-bottom: 0 !important;
    @extend .after;
}


/*=============================
// Modal footer
=============================*/
.modal-footer {
    @extend .justify-content-start;
}

/*=============================
// Modal menu
=============================*/

#menu-modal {
    .modal-body {
        > * {
            padding-top: 5.474rem;
            padding-bottom: 5.474rem;
        }

        .menu-primary {
            .nav {
                @extend .flex-column;
            }
        }
    }

    .modal-footer {
        position: absolute;
        bottom: $modal_header_padding_y;
        width: 100%;
    }
}

/*=============================
// Modal search
=============================*/

#search-modal {
    .modal-body {
        form {
            width: 100%;
            max-width: 1250px;
            padding-left: 5.263rem;
            padding-right: 5.263rem;
            margin: auto;

            @media #{$media_md} {
                padding-left: 2.632rem;
                padding-right: 2.632rem;
            }
        }
    }

    .modal-footer {
        position: absolute;
        bottom: $modal_header_padding_y;
        width: 100%;
    }
}
