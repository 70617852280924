//=================================================
// Projects
//=================================================
.projects {

	.projects-top {
		background-color: $body_background_color_secondary;
		// padding-top: $space_xl;
		// padding-bottom: $space_xl;
		height: 100%;
	
		.wrapper {
			width: 60%;
			display: flex;
  			justify-content: center;
  			align-items: center;
			text-align: center;
		}

		/* For desktop: */
		@media only screen and (min-width: 768px) {
			.wrapper {
				height: 100vh;
			}
		}
	
		/* For mobile phones: */
		@media only screen and (max-width: 768px) {
			.wrapper {
				width: 100%;
				height: 100vh;
			}
		}
	
		h1 {
			color: $body_text_tertiary_color !important;
			font-family: $font_primary !important;
		}
		
		
	}

	.projects-mid {
		background-color: $body_background_color_quinary;
		padding-top: $space_md;
		padding-bottom: $space_md;
	
		.wrapper {
			width: 60%;
			min-width: none;
		}
	
		/* For mobile phones: */
		@media only screen and (max-width: 768px) {
			.wrapper {
				width: 100%;
			}
		}
	
		h2 {
			color: $body_text_tertiary_color !important;
			font-family: $font_primary !important;
		}
		
	}

	.projects-video {
	
		// /* Centering the container's content vertically 
		// and horizontally */
		text-align: left;
		display: flex;

		  
		video {
			width: 100%;
			height: 100vh;

			object-fit: cover;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index:-1;
			position: relative;
		
		  }

		/* For mobile phones: */
		@media only screen and (max-width: 768px) {
			video {
				height: 400px;
			}
		}

		.video-text {
		position: absolute;
		color: white !important;
		z-index: 1 !important;
		bottom: 0;

		.wrapper {
			margin-left: $space_md;

			h1 {
				color: white !important;
				font-family: $font_primary !important;

			}
		}
		}
		  
	}


.technology-section {
	padding-top: $space_xl;
	padding-bottom: $space_xl;
	background-color: #F9F2E8;
	background-image:  linear-gradient(#F6ECDC 2px, transparent 2px), linear-gradient(to right, #F6ECDC 2px, #F9F2E8 2px);
	background-size: 60px 60px;
}

.technology-image {
	
	// /* Centering the container's content vertically 
	// and horizontally */
	// text-align: left;
	// display: flex;
	width: 60%;
	height: 10%;
	display: block;
  	margin-left: auto;
  	margin-right: auto;
	  
	}
}
