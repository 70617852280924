//=================================================
// Main
//=================================================

/*=============================
// All page
=============================*/
main {

    padding-top: 0;
    padding-bottom: 0;

    &.content-no-spacing {
        padding-top: 0;
        padding-bottom: 0;

        #page-content {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

/*=============================
// Home blog page
=============================*/
.home {

    .img {

        min-height: 500px !important;

    }
    
    .page-title {
        padding-bottom: 0px;
    }

    .home-pre-bottom {
        background-color: $body_background_color;
        padding-top: $space_xl;
        padding-bottom: $space_xl;
    
        .wrapper {
            width: 60%;
        }
    
        /* For mobile phones: */
        @media only screen and (max-width: 768px) {
            .wrapper {
                width: 100%;
            }
        }
    
        h2,h3,h5,p {
            color: $body_text_secondary_color !important;
            font-family: $font_primary !important;
        }
    
    }
}
