//=================================================
// Comments
//=================================================

#comments {
    .comment-list {
        margin-top: $space_md;

        .comment, .trackback, .pingback {
            margin-top: $space_md;
            padding-top: $space_md - 0.417rem;
            border-top: 1px solid $body_border_color;
            margin-bottom: 0 !important;

            &:before, &:after {
                display: none;
            }

            &:first-child {
                margin-top: 0;
                padding-top: 0;
                border-top: 0;
            }

            .comment-wrapper {
                .comment-details {
                    .comment-content {
                        display: flex;

                        .comment-content-left {
                            padding-right: $space_md;

                            @media #{$media_md} {
                                padding-right: $space_md / 3.3 * 2;
                            }

                            .comment-img {
                                img {
                                    border-radius: 50%;
                                    width: 4.631579rem !important;
                                    height: 4.631579rem !important;
                                    max-width: inherit;

                                    @media #{$media_md} {
                                        width: 3.684211rem !important;
                                        height: 3.684211rem !important;
                                    }
                                }
                            }
                        }

                        .comment-content-right {
                            .comment-author {
                                font-family: $font_primary_bold;
                                font-weight: 700;
                                margin-bottom: 0;
                            }

                            .comment-description {
                                padding-top: $space_xs;

                                ul {
                                    li {
                                        &:before {
                                            content: "\2022";
                                        }
                                    }
                                }
                            }

                            .comment-reply {
                                padding-top: $space_xs;

                                .comment-reply-link {
                                    display: table;
                                }
                            }

                            &.no-avatar {
                                padding-left: 0;

                                .comment-author {
                                    @media #{$media_md} {
                                        padding-top: 0;
                                    }
                                }

                                .comment-description {
                                    @media #{$media_md} {
                                        padding-top: $space_xs;
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .children {
                margin-left: $space_sm;

                @media #{$media_xl} {
                    margin-left: $space_sm / 3.3 * 2;
                }

                @media #{$media_md} {
                    margin-left: $space_sm / 2;
                }

                .comment {
                    margin-top: $space_md;
                }

                li {
                    margin-top: $space_md;
                    padding-top: $space_md - 0.417rem;
                    border-top: 1px solid $body_border_color;
                }
            }
        }

        #respond {
            margin-top: $space_md;
            margin-bottom: $space_md;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // Respond
    #respond {
        .comment-reply-title {
            color: $body_headline_color;
            margin-bottom: $space_md;

            small {
                &:before, &:after {
                    display: none;
                }
            }
        }

        .comment-form {
            max-width: 40.789474rem;
            
            .comment-notes, .logged-in-as {
                @include lhCrop-b(1.74, 0.75);
                @include lhCrop-a(1.74, 0.75);
            }

            .comment-form-cookies-consent {
                label {
                    font-size: $f_size_lg;
                    font-family: $font_primary;
                    font-weight: 400;
                }
            }

            .form-submit {
                margin-bottom: 0;
            }
        }
    }
}

[class*='block-latest-comments'] {
    > [class*='block-latest-comments__comment'] {
        margin-bottom: $space_pc_primary - $li_font_mb !important;

        [class*='__comment-date'] {
            line-height: 0.8;
            margin-top: $space_pc_secondary / 2.5;
            margin-bottom: $space_pc_secondary / 2 !important;
        }

        [class*='block-latest-comments__comment-avatar'] {
            margin-top: 0.328rem;
            margin-bottom: 0 !important;
        }
    }
}
