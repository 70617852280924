//=================================================
// Fonts
//=================================================

// Roboto-Regular
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../fonts/Roboto/Roboto-Regular/Roboto-Regular.eot');
    src: url('../fonts/Roboto/Roboto-Regular/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Roboto/Roboto-Regular/Roboto-Regular.woff2') format('woff2'),
         url('../fonts/Roboto/Roboto-Regular/Roboto-Regular.woff') format('woff'),
         url('../fonts/Roboto/Roboto-Regular/Roboto-Regular.ttf') format('truetype'),
         url('../fonts/Roboto/Roboto-Regular/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

// Roboto-Bold
@font-face {
    font-family: 'Roboto-Bold';
    src: url('../fonts/Roboto/Roboto-Bold/Roboto-Bold.eot');
    src: url('../fonts/Roboto/Roboto-Bold/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Roboto/Roboto-Bold/Roboto-Bold.woff2') format('woff2'),
         url('../fonts/Roboto/Roboto-Bold/Roboto-Bold.woff') format('woff'),
         url('../fonts/Roboto/Roboto-Bold/Roboto-Bold.ttf') format('truetype'),
         url('../fonts/Roboto/Roboto-Bold/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

/*=============================
// Font size
=============================*/
.font-size {
    &-sm {
        font-size: $f_size_sm;
    }

    &-df {
        font-size: $f_size_df;
    }

    &-lg {
        font-size: $f_size_lg;
    }
}
