//=================================================
// Single
//=================================================

#single {
    .single-content {
        .img {
            margin-bottom: $space_md;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .meta {
            padding-top: $space_md;

            &:first-child {
                padding-top: 0;
            }

            p {
                font-family: $font_primary_bold;
                font-weight: 700;
                font-size: $f_size_lg;
                color: $body_text_secondary_color;
            }

            span {
                margin-right: 0.158rem;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    color: inherit;

                    &:hover {
                        color: $body_links_hover_color;
                    }
                }
            }
        }

        .description {
            margin-top: $space_md - 0.455rem;

            &:first-child {
                margin-top: 0;
            }

            input, textarea, select {
                @extend .form-lg;
            }

            [class*='tag-cloud'] {
                display: table;
                line-height: normal;
                word-break: break-all;
                margin-right: -$space_xs / 2;
                margin-top: -$space_xs / 2;

                .tag-cloud-link {
                    @extend .btn;
                    @extend .btn-secondary;
                    @extend .btn-sm;
                    @extend .min-w-auto;
                    margin-top: $space_xs / 2;
                    margin-right: $space_xs / 2;
                    font-size: $f_size_sm !important;
                }
            }
        }

        .tags {
            padding-top: $space_md;
            padding-bottom: $space_md;
            border-bottom: 1px solid $body_border_color;

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }

            p {
                display: table;
                line-height: normal;
                word-break: break-all;
                margin-right: -$space_xs / 2;
                margin-top: -$space_xs / 2;

                &:before, &:after {
                    display: none;
                }

                a {
                    @extend .btn;
                    @extend .btn-secondary;
                    @extend .btn-sm;
                    @extend .min-w-auto;
                    margin-top: $space_xs / 2;
                    margin-right: $space_xs / 2;
                    font-size: $f_size_sm;
                }
            }
        }
    }
}

/*=============================
// Single post
=============================*/
.single-post, [class*='single-'] {
    #single {
        .single-content {
            .img {
                &.object-fit {
                    min-height: 21.053rem;
                    max-height: 28.947rem;

                    @media #{$media_md} {
                        max-height: 21.053rem;
                    }

                    &:before {
                        padding-top: 57.155%;
                    }
                }
            }
        }
    }
}
