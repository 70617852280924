//=================================================
// Applications
//=================================================

#applications {
	
	.text-tertiary {
		p {
			color: rgba($body_text_tertiary_color, 0.5) !important;
		}

		.adv-swiper-control {
			
			.adv-3-swiper-button {
				&-prev, &-next {
					color: $body_text_tertiary_color;

					&:not(.adv-swiper-button-disabled) {
						&:before, &:after {
							background-color: $body_text_tertiary_color;
						}
					}

					&:hover {
						opacity: 0.5;
					}

					&.adv-swiper-button-disabled {
						opacity: 0.5;
					}
				}
			}
		}
	}
}

/*=============================
// Applications slider
=============================*/

.swiper {

	background-color: $body_background_color_quinary;
	padding-top: $space_xl;
	padding-bottom: $space_xl + 3*$space_sm;

	.wrapper {
		width: 60%;
		h2 {
			font-family: $font_primary !important;
		}
		p {
			color: black;
		}
	}

	/* For mobile phones: */
	@media only screen and (max-width: 768px) {
		.wrapper {
			width: 100%;
		}
	}


.swiper-container {
	.swiper-wrapper {
		
	  .swiper-slide {
		.card-image {

		  img {
			display: block;
			position: relative;
			left: -10%;
			
			// bottom: 0;
			width: 120%;
			height: 120%;

		  }

		  /* For mobile phones: */
			@media only screen and (max-width: 768px) {
				img {
					// width: 100%;
					min-width: 350px;
					left: -62%;
				}

				.bottom-left {
					left: -62%;
				}
			}
		}
	  }
	}

	/* Bottom left text */
	.bottom-left {
		position: absolute;
		bottom: 0%;
		left: -10%;
		background-color: black;
		padding: 1%;
	}

		.swiper-pagination {
		&-bullet {
			opacity: 0.8;
			background: black;
			&-active {
			background: white;
			}
		}
		}

		.swiper-button-disabled {
			visibility: hidden;
			opacity: 0;
			transition: 0.5s ease-in-out;
		}

		.swiper-button-next,
		.swiper-button-prev {

		.arrow-icon-right {
			position: absolute;
			color: white !important;
			z-index: 1 !important;
			top: 46%;
			right: 3%;
			font-size: 300%;
		}

		.arrow-icon-left {
			position: absolute;
			color: white !important;
			z-index: 1 !important;
			// bottom: 100px;
			top: 46%;
			left: 3%;
			font-size: 300%;
		}
		}

	}
}


// .acc {
// 	margin: 90px 0;
// 	overflow: hidden;
// 	padding: 0;
//   }
  
//   .acc li {
// 	list-style-type: none;
// 	padding: 0;
//   }
  
//   .acc_ctrl {
// 	background: #FFFFFF;
// 	border: none;
// 	border-bottom: solid 1px #F2F2F2;
// 	cursor: pointer;
// 	display: block;
// 	outline: none;
// 	padding: 2em;
// 	position: relative;
// 	text-align: center;
// 	width: 100%;
//   }
  
//   .acc_ctrl:before {
// 	background: #44596B;
// 	content: '';
// 	height: 2px;
// 	margin-right: 37px;
// 	position: absolute;
// 	right: 0;
// 	top: 50%;
// 	-webkit-transform: rotate(90deg);
// 	-moz-transform: rotate(90deg);
// 	-ms-transform: rotate(90deg);
// 	-o-transform: rotate(90deg);
// 	transform: rotate(90deg);
// 	-webkit-transition: all 0.2s ease-in-out;
// 	-moz-transition: all 0.2s ease-in-out;
// 	-ms-transition: all 0.2s ease-in-out;
// 	-o-transition: all 0.2s ease-in-out;
// 	transition: all 0.2s ease-in-out;
// 	width: 14px;
//   }
  
//   .acc_ctrl:after {
// 	background: #44596B;
// 	content: '';
// 	height: 2px;
// 	margin-right: 37px;
// 	position: absolute;
// 	right: 0;
// 	top: 50%;
// 	width: 14px;
//   }
  
//   .acc_ctrl.active:before {
// 	-webkit-transform: rotate(0deg);
// 	-moz-transform: rotate(0deg);
// 	-ms-transform: rotate(0deg);
// 	-o-transform: rotate(0deg);
// 	transform: rotate(0deg);
//   }
  
//   .acc_ctrl.active h2, .acc_ctrl:focus h2 {
// 	position: relative;
//   }
  
//   .acc_panel {
// 	background: #F2F2F2;
// 	display: none;
// 	overflow: hidden;
//   }