//=================================================
// Footer
//=================================================

.site-footer {
    background-color: $footer_background_color;
    
    
    }

    .wrapper {
        position: relative;
        padding-top: $footer_padding_b;
        padding-bottom: $footer_padding_b;


        .footer-menu {
            position: relative;
            padding-bottom: $footer_menu_padding_b;

            .nav {
                @extend .flex-column;
                .nav-item { 
                    padding-bottom: $footer_menu_nav_item_padding_b;
            
                    // Hover
                    &:hover {
                        > a:not(.disabled):not(.active) {
                            color: $footer_menu_text_color;
                        }
                    }

                    a { 
                        color: $footer_menu_text_color; 
                        font-size: $h4_font_size;
                        font-family: $font_primary;
                        font-weight: 400;
                    } 
                }
            }


        .footer {
            @media #{$media_md} {
                margin-top: $space_sm;

                .d-flex {
                    > div {
                        margin-bottom: $space_sm;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .copyright {
                p {
                    font-size: $f_size_df;
                }

                @media #{$media_md} {
                    text-align: center;
                }
            }

        }
        
    }

    	/* For mobile phones: */
	@media only screen and (max-width: 768px) {
        .logo { 
            padding-bottom: 5% !important;
        }
	}


    
}


