//=================================================
// Contacts
//=================================================


.contacts-top {
	background-color: $body_background_color_secondary;
	padding-bottom: $space_xl;


	.wrapper {
		width: 60%;
	}

	/* For mobile phones: */
	@media only screen and (max-width: 768px) {
		.wrapper {
			width: 100%;
		}
	}

	h1 {
		padding-bottom: $space_md;
		text-align: center;
		color: $body_text_secondary_color !important;
		font-family: $font_primary !important;
	}
	
}
