//=================================================
// technologyApplications
//=================================================

.technologyApplications {
	background-color: $body_background_color_quaternary ;
	padding-bottom: 20%;
	padding-top: 10%;
	background-color: #F9F2E8;
	// background-image:  linear-gradient(#F6ECDC 2px, transparent 2px), linear-gradient(to right, #F6ECDC 2px, #F9F2E8 2px);
	// background-size: 60px 60px;


	.wrapper {
		h2{
			margin-left: auto;
		color: $body_text_tertiary_color !important;
		}

		h4 {
			width: 60%;
			font-family: $font_primary !important;
		} 
	}

	.technologyApplications-items {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		margin-top: 5%;
		

		.technologyApplications-item {
			position: relative;
			background-color: $white;
			border: 0px solid $body_border_color;
			flex: 0 0 20%;
			max-width: 20%;

			&[class*='bg-'] {
				border-color: transparent;
			}

			&:nth-child(odd) {
				margin-bottom: 20%;

				@media #{$media_md} {
					margin-bottom: 0;
				}
			}

			@media #{$media_md} {
				flex: 0 0 50%;
				max-width: 50%;
				padding-bottom: 50%;
				padding-left: $wrapper_p_l_sm;
				padding-right: $wrapper_p_r_sm;
			}

			.technologyApplications-item-content {
				// max-width: 11.632rem;
				text-align: center;
				position: absolute;
				top: 0%;
				left: 0%;
				// @include transform(translate(-50%, -50%));

				
				img {
					max-width: 100%;
					// max-height: 15rem;
				}

				.img-caption {
					margin-top: 5%;
					font-family: $font_primary !important;
					color: $body_text_tertiary_color; 
				}
			}
		}
	}
}
