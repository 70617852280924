//=================================================
// Variables
//=================================================

//=============================
// Media queries
//=============================
$retina:     "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
$media_xxxl: "(min-width: 1920px)";
$media_xxl:  "(max-width: 1700px)";
$media_xl:   "(max-width: 1199px)";
$media_lg:   "(max-width: 991px)";
$media_md:   "(max-width: 767px)";
$media_sm:   "(max-width: 575px)";

//=============================
// Paths
//=============================
$img:   "../img/";
$fonts: "../fonts/";
$icons: "../icons/";

//=============================
// Colors
//=============================
$white:                                               #ffffff;
$blue:                                                #0f50b8;
$indigo:                                              #662ed9;
$purple:                                              #9d2ed9;
$pink:                                                #de1cc1;
$red:                                                 #dd1111;
$orange:                                              #f3b03d;
$yellow:                                              #f3d10d;
$green:                                               #15d40b;
$teal:                                                #34dca9;
$cyan:                                                #42b8cb;

$gray:                                                #adadad;
$gray-light:                                          #efefef;
$gray-dark:                                           #8a8a8a;

$light:                                               #efefef;
$dark:                                                #000000;

// Body
// https://docs.google.com/document/d/11KJqabuErei34msYw6i8MBs1bL-ORwaOrVCAgr3pusY/edit
$body_text_primary_color:                             #ffffff;
$body_text_secondary_color:                           #000000;
$body_text_tertiary_color:                            #151515; 
$body_text_quaternary_color:                          #E9DFCA;
$body_text_quinary_color:                             #494949;
$body_text_seven_color:                               #7F835E;
$body_headline_color:                                 #000000;
$body_links_color:                                    #000000;
$body_links_hover_color:                              #f3b03d;




$body_main_element_primary_color:                     #000000;
$body_main_element_secondary_color:                   #004c65;
$body_main_element_tertiary_color:                    #e4c390;
$body_main_element_quaternary_color:                  #333a2f;
$body_main_element_quinary_color:                     #e7dfcc;
$body_main_element_senary_color:                      #F9F2E8;
$body_main_element_seven_color:                       #B4B7A7;

$body_background_color:                               #F9F2E8;
$body_background_color_secondary:                     #ffffffff;
$body_background_color_tertiary:                      #151515;
$body_background_color_quaternary:                    #F9F2E8;
$body_background_color_quinary:                       #EBC288;
$body_background_color_senary:                       #A15942;


$body_border_color:                                   #dedede;
$body_mark_background:                                #fcf8e3;

// Header
$header_color:                                        #000000;
$header_background_color:                             transparent;

// Footer
$footer_color:                                        #ffffff;
$footer_background_color:                             #A15942;
$footer_menu_text_color:                              #ffffff;

// Subscribe
$subscribe_background_color:                          #EBC288;
$subscribe_text_color:                                #000000;

// Menu
$menu_links_color:                                    #000000;
$menu_links_hover_color:                              #f3b03d;
$menu_links_active_color:                             #f3b03d;

// Menu modal
$menu_modal_links_color:                              #ffffff;
$menu_modal_links_hover_color:                        #f3b03d;
$menu_modal_links_active_color:                       #f3b03d;

// Dropdown
$dropdown_links_color:                                #000000;
$dropdown_links_hover_color:                          #ffffff;
$dropdown_links_active_color:                         #ffffff;
$dropdown_links_background_color:                     #ffffff;
$dropdown_links_hover_background_color:               #f3b03d;
$dropdown_links_active_background_color:              #f3b03d;

// Forms
$input_text_color:                                    #000000;
$input_border_focus_color:                            #f3b03d;
$input_placeholder_color:                             #979797;
$label_color:                                         #000000;

// Form primary
$input_background_primary_color:                      transparent;
$input_border_primary_color:                          #dedede;

// Form secondary
$input_background_secondary_color:                    #ffffff;
$input_border_secondary_color:                        #dedede;

// Modals
$modal_content_color:                                 #ffffff;
$modal_background_color:                              #004c65;
$modal_background_content_color:                      transparent;

// Card
$card_border_color:                                   transparent;
$card_background_color:                               transparent;

// Widget
$widget_background_color:                             #ffffff;
$widget_border_color:                                 #dedede;
$widget_headline_color:                               #000000;

// Slider
$slider_button_color:                                 #ffffff;
$slider_button_background_color:                      #f3b03d;
$slider_button_hover_color:                           #f3b03d;
$slider_button_hover_background_color:                #ffffff;

// Map
$map_marker_color:                                    #ffffff;
$map_marker_background_color:                         #f3b03d;
$map_marker_hover_color:                              #ffffff;
$map_marker_hover_background_color:                   #f37f1f;

//=============================
// Fonts
//=============================
$ROBOTO:                                              'Roboto-Regular', sans-serif;
$ROBOTO_b:                                            'Roboto-Bold', sans-serif;

$font_primary:                                        $ROBOTO;
$font_primary_bold:                                   $ROBOTO_b;

$f_size_ti:                                           0.325rem; // 10px

$f_size_sm:                                           0.789474rem; // 15px
$f_size_df:                                           0.842105rem; // 16px
$f_size_lg:                                           1rem;        // 19px

//=============================
// Spacing
//=============================
$space_xl:                                            8.421rem; // 160px
$space_lg:                                            3.158rem; // 60px
$space_md:                                            2.632rem; // 50px
$space_sm:                                            1.579rem; // 30px
$space_xs:                                            1.316rem; // 25px

// For page content
$space_pc_primary:                                    2.632rem; // 50px
$space_pc_secondary:                                  1.579rem; // 30px

//=============================
// Header
//=============================
$header_padding_t:                                    2.105263rem;
$header_padding_b:                                    2.105263rem;

//=============================
// Main
//=============================
$main_padding_t:                                      5.263158rem;
$main_padding_b:                                      5.263158rem;

//=============================
// About
//=============================
$about_middle_padding_t:                                    0.105263rem;
$about_middle_padding_b:                                    0.105263rem;

//=============================
// Technology
//=============================
$applications_padding_t:                                    18rem;
$applications_padding_b:                                    6rem;

//=============================
// Subscribe
//=============================
$subscribe_padding_t:                                    0.105263rem;
$subscribe_padding_b:                                    0.105263rem;

//=============================
// Footer
//=============================
$footer_padding_t:                                    3rem;
$footer_padding_b:                                    3rem;

$footer_menu_padding_b:                               8rem;
$footer_menu_nav_item_padding_b:                      1.5em;


//=============================
// Wrapper
//=============================
$wrapper_max_w:                                       8000px;

// Left
$wrapper_p_l:                                         2.631579rem;
$wrapper_p_l_xxl:                                     2.632rem;
$wrapper_p_l_xl:                                      1.59515rem;
$wrapper_p_l_sm:                                      1.59515rem;

// Right
$wrapper_p_r:                                         2.631579rem;
$wrapper_p_r_xxl:                                     2.632rem;
$wrapper_p_r_xl:                                      1.59515rem;
$wrapper_p_r_sm:                                      1.59515rem;

// Central
$wrapper_p_c:                                         50.0rem;

//=============================
// Transition s
//=============================
$transition-s:                                        0.3s;

//=============================
// Components
//=============================
$border_radius:                                       0.25rem;
$border_radius_sm:                                    0.3rem;
$border_radius_lg:                                    0.2rem;

$caret_vertical_align:                                middle;
$caret_spacing:                                       0.263rem;

$disabled_opacity:                                    0.5;

//=============================
// Typography
//=============================
$font_family_base:                                    $font_primary;

$h1_font_size:                                        3.052632rem; // 58px
$h2_font_size:                                        2.421053rem; // 46px
$h3_font_size:                                        1.842105rem; // 35px
$h4_font_size:                                        1.578947rem; // 30px
$h5_font_size:                                        1.263158rem; // 24px
$h6_font_size:                                        1rem;        // 19px

$line_height_base:                                    1;
$body_color:                                          $body_text_primary_color;

$hr_border_color:                                     $body_border_color;

// Font margin bottom
$h1_font_mb:                                          1.005421rem;
$h2_font_mb:                                          0.660684rem;
$h3_font_mb:                                          0.502684rem;
$h4_font_mb:                                          0.430895rem;
$h5_font_mb:                                          0.344684rem;
$h6_font_mb:                                          0.270263rem;
$p_font_mb:                                           0.559211rem;
$li_font_mb:                                          0.403684rem;

//=============================
// Links
//=============================
$link_color:                                          $body_links_color;
$link_hover_color:                                    $body_links_hover_color;
$link_hover_decoration:                               none;

//=============================
// Buttons
//=============================
$btn_font_family:                                     $font_primary;

$btn_padding_y:                                       0.895rem;
$btn_padding_x:                                       0.895rem;
$btn_padding_y_sm:                                    0.421rem;
$btn_padding_x_sm:                                    0.421rem;
$btn_padding_y_lg:                                    1.474rem;
$btn_padding_x_lg:                                    1.474rem;

$btn_font_family:                                     $font_primary_bold;
$btn_font_weight:                                     700;

$btn_font_size:                                       $f_size_df;
$btn_font_size_sm:                                    $f_size_sm;
$btn_font_size_lg:                                    $f_size_lg;

$btn_line_height:                                     1.23;
$btn_line_height_sm:                                  1.23;
$btn_line_height_lg:                                  1.23;

$btn_border_radius:                                   0;
$btn_border_radius_sm:                                0;
$btn_border_radius_lg:                                0;

$btn_focus_width:                                     0;

$btn_transition:                                      color $transition-s ease-in-out, background-color $transition-s ease-in-out, border-color $transition-s ease-in-out, box-shadow $transition-s ease-in-out, opacity $transition-s ease-in-out;

//=============================
// Badge
//=============================
$badge_border_radius:                                 0;

//=============================
// Modals
//=============================
$modal_footer_margin_between:                         0;

$modal_inner_padding:                                 1.526rem 0;
$modal_dialog_margin:                                 1.526rem;

$modal_content_bg:                    	              $modal_background_content_color;
$modal_content_border_color:                          transparent;
$modal_content_border_width:                          0;
$modal_content_border_radius:                         0;

$modal_backdrop_bg:                  	              $modal_background_color;
$modal_backdrop_opacity:              	              1;
$modal_header_border_color:                           transparent;
$modal_header_border_width:                           0;
$modal_footer_border_color:                           transparent;
$modal_footer_border_width:                           0;

$modal_header_padding_y:                              $header_padding_t;
$modal_header_padding_x:                              0;
$modal_header_padding:                                $header_padding_t 0 $header_padding_b 0;

//=============================
// Alert
//=============================
$alert_border_radius:                                 0;
$alert_padding_y:                                     0.75rem;
$alert_padding_x:                                     0.75rem;

//=============================
// List group
//=============================
$list_group_border_color:                             $body_border_color;
$list_group_border_radius:                            0;

$list_group_item_padding-y:                           $space_xs;
$list_group_item_padding-x:                           $space_xs;

//=============================
// Close
//=============================
$close_color:                                         $body_links_color;

//=============================
// Forms
//=============================
$label_margin_bottom:                                 0;

$input_padding_y:                                     1.158rem / 1.5;
$input_padding_x:                                     1.421rem / 1.5;
$input_padding_y_sm:                                  1.158rem / 2.5;
$input_padding_x_sm:                                  1.421rem / 2.5;
$input_padding_y_lg:                                  1.158rem;
$input_padding_x_lg:                                  1.421rem;
$input_font_size:                                     $f_size_df;
$input_font_size_sm:                                  $f_size_sm;
$input_font_size_lg:                                  $f_size_lg;
$input_line_height:                                   1.31;
$input_line_height_sm:                                1.31;
$input_line_height_lg:                                1.31;

$input_bg:                                            $input_background_secondary_color;

$input_color:                                         $input_text_color;

$input_border_color:                                  $input_border_secondary_color;
$input_border_radius:                                 0;
$input_border_radius_lg:                              0;
$input_border_radius_sm:                              0;

$input_focus_border_color:                            $input_border_focus_color;
$input_focus_box_shadow:                              inherit;

$input_height:                                        auto;
$input_height_sm:                                     auto;
$input_height_lg:                                     auto;

$form_check_input_gutter:                             1.526rem;
$form_check_input_margin_y:                           0;
$form_check_input_margin_x:                           0.526rem;

$form_group_margin_bottom:                            $space_sm;

$input_group_addon_bg:                                $input_border_color;

$custom_control_gutter:                               $f_size_df;

$custom_control_indicator_size:                       $f_size_df;
$custom_control_indicator_checked_bg:                 $body_main_element_primary_color;
$custom_control_indicator_border_color:               $body_text_primary_color;

$custom_checkbox_indicator_border_radius:             0;

$custom_select_border_radius:                         0;
$custom_select_focus_box_shadow:                      inherit;

//=============================
// Buttons + Forms
//=============================
$input_btn_focus_box_shadow:                          inherit;

//=============================
// Navs
//=============================
$nav_link_padding_y:                                  $space_xs / 2;
$nav_link_padding_x:                                  $space_xs;
$nav_link_disabled_color:                             rgba($menu_links_color, $disabled_opacity);

$nav_tabs_border_radius:                              0;
$nav_tabs_link_active_color:                          $menu_links_active_color;

$nav_pills_border_radius:                             0;
$nav_pills_link_active_color:                         $body_text_tertiary_color;
$nav_pills_link_active_bg:                            $body_main_element_primary_color;

//=============================
// Dropdowns
//=============================
$dropdown_min_width:                                  12.105rem;
$dropdown_padding_y:                                  0;
$dropdown_spacer:                                     0;
$dropdown_font_size:                                  0.789rem;
$dropdown_color:                                      $dropdown_links_color;
$dropdown_border_color:                               transparent;
$dropdown_bg:                                         $dropdown_links_background_color;
$dropdown_border_radius:                              0;
$dropdown_border_width:                               0;
$dropdown_divider_bg:                                 $body_border_color;
$dropdown_divider_margin_y:                           0;

$dropdown_link_color:                                 $dropdown_links_color;
$dropdown_link_hover_color:                           $dropdown_links_hover_color;
$dropdown_link_hover_bg:                              $dropdown_links_hover_background_color;

$dropdown_link_active_color:                          $dropdown_links_active_color;
$dropdown_link_active_bg:                             $dropdown_links_active_background_color;
$dropdown_link_disabled_color:                        rgba($dropdown_links_color, $disabled_opacity);

$dropdown_item_padding_y:                             0.789rem;
$dropdown_item_padding_x:                             1.053rem;

//=============================
// Pagination
//=============================
$pagination_padding_x:                                0.953rem;
$pagination_padding_y:                                0.632rem;

$pagination_border_width:                             0;
$pagination_border_color:                             transparent;

$pagination_hover_border_color:                       transparent;

$pagination_disabled_color:                           rgba($body_links_color, $disabled_opacity);

//=============================
// Card
//=============================
$card_spacer_y:                                       $space_sm;
$card_spacer_x:                                       $space_sm;
$card_cap_bg:                                         $card_background_color;
$card_bg:                                             $card_background_color;
$card_border_color:                                   $card_border_color;
$card_border_radius:                                  0;
$card_inner_border_radius:                            0;
$card_img_overlay_padding:                            $space_sm;

//=============================
// Table
//=============================
$table_caption_color:                                 $body_text_primary_color;
