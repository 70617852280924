 //=================================================
// About
//=================================================

.about-content {
	.about-left {
		color: $body_text_primary_color;
		
		@media #{$media_lg} {
			padding-bottom: $space_md;
		}
	}
}

.about-top {
	background-color: $body_background_color_secondary;
	padding-bottom: $space_xl;
	width: 100%;

	.wrapper {
		width: 60%;
	}

	/* For mobile phones: */
	@media only screen and (max-width: 768px) {
		.wrapper {
			width: 100%;
		}
	}

	h4 {
		color: $body_text_secondary_color !important;
		font-family: $font_primary !important;
	}
	
}

.about-us-image-wrapper1 {

	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 95%;

	h2,h3,h5,p {
		color: $body_text_secondary_color !important;
		font-family: $font_primary !important;
	}


	.head-computer-vision-img-caption-name {
		padding-top: 3%;
	}

	.head-computer-vision-img-caption-role {
		padding-top: 2%;
	}

	.head-engineering-img-caption-name {
		padding-top: 3%;
	}

	.head-engineering-img-caption-role {
		padding-top: 2%;
	}
	
  }

  /* For mobile phones: */
@media only screen and (max-width: 768px) {
	.head-computer-vision {
		margin-bottom: 10%;
	}
}

.about-under-top {
	background-color: $body_background_color;
	padding-top: $space_xl;
	padding-bottom: $space_xl;

	.ceo-quote {
		h2 {
			color: $body_text_quinary_color !important;
		}
	}

	.wrapper {
		width: 80%;

		h3,h5,p {
			color: $body_text_secondary_color !important;
			font-family: $font_primary !important;
		}

		

	}

	/* For mobile phones: */
	@media only screen and (max-width: 768px) {
		.wrapper {
			width: 100%;
		}
	}

	h2,h3,h5,p {
		color: $body_text_secondary_color !important;
		font-family: $font_primary !important;
	}

	.ceo-img {
		margin: auto;
		width: 55%;

		.ceo-img-caption-name {
			padding-top: 3%;
		}

		.ceo-img-caption-role {
			padding-top: 2%;
		}
	}

	/* For mobile phones: */
	@media only screen and (max-width: 768px) {
		.ceo-img {
			width: 90%;
		}
	}
	
}
 

.about-middle {
	background-color: $body_background_color_secondary;
	// min-height: 1400px;
	overflow: hidden;

	article {
		background-color: $body_background_color_secondary;
		float: left;
		
		&:nth-child(even) {
		  .image {
			float: left;
		  }
		  .text {
			float: right;
		  }
		}
		h2,h3,h5,p  {
			color: $body_text_secondary_color !important;
			font-family: $font_primary !important;
		}

		.text-wrapper {
			width: 80% ;
			margin: auto;
			padding: 0.5rem;
			
		}

		/* For mobile phones: */
		@media only screen and (max-width: 768px) {
			.text-wrapper {
				width: 100%;
			}
		}
		  
	  }
	  
	  
	  img {
		max-width: 100%;
	  }
	  
	  .box {
		box-sizing: border-box;
		float: left;
		margin: 0;
		padding: 0 0rem;
		width: 100%;
	  }
	  
	  .col-50 {
		width: 50%;
	  }
	  
	  @media (max-width: 979px) {
		.col-50 {
		  width: 100%;
		}

		.re-order {
			display: flex;
			display: -ms-flex;
			flex-direction: column;
		  }
		  .re-order .text {
			order: 2;
		  }
		  .re-order .image {
			order: 1;
		  }
		
	  }

	
}

.about-pre-bottom {

	background-color: $body_background_color_tertiary;
	padding-top: $space_xl;
	padding-bottom: $space_xl;

	.wrapper {
		width: 80%;
		
	}

	/* For mobile phones: */
	@media only screen and (max-width: 768px) {
		.wrapper {
			width: 100%;
		}
	}

	h2, h4,p {
		color: $body_text_primary_color !important;
		font-family: $font_primary !important;
	}

}

.about-bottom {
	background-color: $body_background_color;
	padding-top: $space_xl;
	padding-bottom: $space_xl;

	.wrapper {
		width: 60%;
		
	}

	/* For mobile phones: */
	@media only screen and (max-width: 768px) {
		.wrapper {
			width: 100%;
		}
	}

	h4 {
		color: $body_text_secondary_color !important;
		font-family: $font_primary !important;
	}
	
}



.careers-video {
	
	// /* Centering the container's content vertically 
	// and horizontally */
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	  
	video {
		width: 100%;
		height: 100%;

		/* For mobile phones: */
		min-height: 500px;

		object-fit: cover;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index:-1;
		position: relative;
	  }

	  .video-text {
		position: absolute;
		color: white !important;
		font-family: Trebuchet MS;
		font-weight: bold;
		text-align: center;
		z-index: 1 !important;

		.wrapper {
			width: 50%;

			h2 {
				font-family: $font_primary !important;
				color: white !important;
			}
		}

		/* For mobile phones: */
		@media only screen and (max-width: 768px) {

			.wrapper {
				width: 100%;
			}

			.videoTag {
				width: 100%;
				height: 1000px;
			}
		}
	  }
	  
}
